import Chart, { ChartConfiguration, ChartItem } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

export const drawLineChart = (
  id: string,
  pielabel: string[] | number[],
  givenData: number[] | string[],
  hasDataSet: boolean
) => {
  const chartDiv = document.getElementById(id);
  const labels = pielabel;
  function checkDataType() {
    if (hasDataSet) {
      return givenData;
    } else {
      return [
        {
          label: "",
          backgroundColor: "#385463",
          borderColor: "#493698",
          data: givenData,
          useLineStyle: true,
          usePointStyle: true,
        },
      ];
    }
  }
  const data = {
    labels: labels,
    datasets: checkDataType(),
  };
  const config = {
    type: "line",
    data,
    options: {
      responsive: true,
      plugins: {
        legend: {
          display: true,
          labels: {
            useLineStyle: true,
            usePointStyle: true,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                callback: function (value: any, index: any, values: any) {
                  return "$" + value;
                },
              },
            },
          ],
          xAxes: [
            {
              position: "top",
            },
          ],
        },
        datalabels: {
          display: true,
          align: "center",
          borderRadius: 3,
          font: {
            size: 18,
          },
          formatter: function (value: any, context: any) {
            if (value > 0)
              return String.fromCharCode(context.dataIndex + 1 + 64);
            else return "";
          },
        },
      },
    },
  };

  if (chartDiv) {
    const myChart = new Chart(
      chartDiv as ChartItem,
      config as ChartConfiguration
    );
    console.log("line chart id ", myChart.id);
    return myChart;
  }
};
