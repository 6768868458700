import { Vue, Component } from "vue-property-decorator";
@Component
export default class Common extends Vue {
    setGradeBadgeByRating(grade: string) {
        return {
            "bg-rating-a": grade === "A",
            "bg-rating-b": grade === "B",
            "bg-rating-c": grade === "C",
            "bg-rating-d": grade === "D",
            "bg-rating-f": grade === "F",
            "bg-rating-t": grade === "T",
            "bg-rating-na": ((grade === null) || (grade === "N/A")),
        };
    }
}