
import { Component, Mixins } from "vue-property-decorator";
import { mapState } from "vuex";
import { BaseModalType } from "@/models/schoolfinder.enums";
import CommonMixin from "@/mixins/common.mixin";
import SchoolMixin from "@/mixins/school.mixin";
import {
  StudentPerformanceData,
  K12School,
  StudentPerformanceScore,
} from "@/models/interface";

import { drawLineChart } from "@/assets/js/line-chart";
import { Chart } from "chart.js";

@Component({
  computed: { ...mapState("app", ["mobileView", "tabletView"]) },
})
export default class StudentPerformance extends Mixins(CommonMixin, SchoolMixin) {
  mobileView!: boolean;
  tabletView!: boolean;
  modalHeaderText = "";
  selectedModalType = BaseModalType.None;
  viewSideBarInfo = false;
  viewSideBarDetails = false;
  BaseModalType = BaseModalType;
  modalMessage = "";
  videoSrc = "";
  showItemDetail = false;
  sideBarName = "";
  sideBarMessage = "";
  infoSideBarTitle = "";
  infoSideBarMessage = "";
  studentPerformanceData: StudentPerformanceData = new StudentPerformanceData();
  sideBarScores: StudentPerformanceScore[] = [];
  isChartDataExists = true;
  arrGrades = [
    {
      grade: "A",
      minScore: 90,
    },
    {
      grade: "B",
      minScore: 75,
    },
    {
      grade: "C",
      minScore: 60,
    },
    {
      grade: "D",
      minScore: 50,
    },
    {
      grade: "F",
      minScore: 0,
    },
  ];

  constructor() {
    super();
  }

  mounted() {
    const schoolDetails: K12School = this.$store.getters["app/getschool"];
    if (schoolDetails && schoolDetails.studentPerformance) {
      this.studentPerformanceData = schoolDetails.studentPerformance;
    }
  }

  showModal(modalType: BaseModalType, header: string, content: string) {
    this.modalHeaderText = header;
    this.selectedModalType = modalType;

    switch (modalType) {
      case BaseModalType.Message:
        this.modalMessage = content;
        break;
      case BaseModalType.Video:
        this.videoSrc = content;
        break;
      default:
        break;
    }

    this.showItemDetail = true;
  }

  onNoClick() {
    this.showItemDetail = false;
    this.selectedModalType = BaseModalType.None;
    this.modalHeaderText = "";
    this.modalMessage = "";
    this.videoSrc = "";
  }

  get selectedModalData() {
    return this.modalData.find(
      (item) => item.modalType === this.selectedModalType
    );
  }

  get modalData() {
    return [
      {
        modalType: BaseModalType.Video,
        headerText: "Student Performance",
        videoSrc: this.videoSrc,
      },
      {
        modalType: BaseModalType.Message,
        headerText: this.modalHeaderText,
        modalMessage: this.modalMessage,
      },
      {
        modalType: BaseModalType.None,
        headerText: "",
        modalMessage: "",
      },
    ];
  }

  getDataPointDesc(sideBar: string) {
    if (sideBar == "mastery") return "Students scoring at mastery and above on LEAP (Elementary / Middle School) in " + this.yearFromEntity;
    else if (sideBar == "masteryHighSchool") return "Students scoring at mastery and above on LEAP (High School) in " + this.yearFromEntity;
    else if (sideBar == "creditAccumulation") return "Students on track at the end of 9th grade in " + this.yearFromEntity;
    else if (sideBar == "act21") return "Percent of students scoring at least a 21 on the act or gold on workkeys in " + this.yearFromEntity;
    else if (sideBar == "actComposite") return "ACT Composite In " + this.yearFromEntity;
    else if (sideBar == "gradRate4yr") return "Graduation Rate In " + this.yearFromEntity;
    else if (sideBar == "gradsAnyCredentials") return "Graduates Earning Credentials In " + this.yearFromEntity;
    else if (sideBar == "collegeEnrollment") return "College Enrollment Rate in " + this.yearFromEntity;
    else if (sideBar == "fafsaRates") return "Free Application For Federal Student AID (FAFSA) Submission Rate in " + this.yearFromEntity;
    else if (sideBar == "collegeEnrollment") return "College Enrollment Rate in " + this.yearFromEntity;
    else if (sideBar == "studentsStayingOnTrack") return "Students Staying On Track in " + this.yearFromEntity;
    else if (sideBar == "coreCredentialRateAlt") return "Students Successfully Transitioning Into The Next School Year in " + this.yearFromEntity;
    else return "";
  }  

  setSidebar(sideBar: string) {
    this.viewSideBarInfo = false;
    this.viewSideBarDetails = true;

    this.sideBarName = sideBar;
    this.viewDetails();
  }

  getInfoSideBar(InfoSideBar: string) {
    this.viewSideBarInfo = true;
    this.viewSideBarDetails = false;

    switch (InfoSideBar) {
      case "mastery":
        this.infoSideBarTitle = "What is this measure?";
        this.infoSideBarMessage = "Mastery and above on the LEAP assessments indicate student proficiency of grade-level content and true readiness for the next level of study.";
        break;
      case "creditAccumulation":
        this.infoSideBarTitle = "What is this measure?";
        this.infoSideBarMessage = "This indicator measures how well schools are preparing 8th graders for success in high school by the number of Carnegie credits they earn by the end of 9th grade. Students who earn at least six credits are considered on track to graduate in four years.";
        break;
      case "act21":
        this.infoSideBarTitle = "What is this measure?";
        this.infoSideBarMessage = "This indicator measures the percentage of students earning a 21 or higher on the ACT or a Gold WorkKeys Certificate or higher indicating students’ readiness for college or the workforce.";
        break;
      case "gradRate4yr":
        this.infoSideBarTitle = "What is this measure?";
        this.infoSideBarMessage = "This indicator measures the percentage of students graduating within four years with a high school diploma. This indicator lags one year so the year that is reported equates to the previous year’s graduating class. For example, a &quot;2016-2017&quot;  graduation rate measures the rate for the class of 2016.";
        break;
      case "gradsAnyCredentials":
        this.infoSideBarTitle = "What is this measure?";
        this.infoSideBarMessage = "This indicator measures the percentage of the cohort who completed college-level coursework or attained proficiency with an industry-valued skill set recognized by the Workforce Investment Council (WIC).";
        break;
      case "collegeEnrollment":
        this.infoSideBarTitle = "What is this measure?";
        this.infoSideBarMessage = "This indicator measures the rate at which students enrolled in college by the 1st fall semester after graduating from high school.";
        break;
      case "fafsaRates":
        this.infoSideBarTitle = "What is this measure?";
        this.infoSideBarMessage = "This indicator measures the rate at which students are submitting the FAFSA for financial aid for post-secondary education, a requirement for all graduating seniors.";
        break;
      case "studentsStayingOnTrack":
        this.infoSideBarTitle = "What is this measure?";
        this.infoSideBarMessage = "This indicator measures how well alternative schools are ensuring students stay on track by measuring the number of Carnegie credits they earn in core academic coursework during each semester that they are enrolled at the alternative school. Students who earn at least two core academic credits per semester are considered on track.";
        break;
      case "coreCredentialRateAlt":
        this.infoSideBarTitle = "What is this measure?";
        this.infoSideBarMessage = "This indicator measures how well alternative schools are helping students successfully transition to the next school year by measuring the number of Carnegie units they earn by the end of the next school year following attendance at the alternative school. Students who earn at least six credits are considered to have had a successful transition.";
        break;
      default:
        (this.infoSideBarTitle = ""), (this.infoSideBarMessage = "");
        break;
    }
  }

  getSideBarData(type: string) {
    this.sideBarMessage = this.schoolSystem ? "How did this school system perform compared to the state?" : "How did this school perform compared to the school system and state?";

    if (this.sideBarName === "mastery") {
      if (type === "school") return this.studentPerformanceData.mastery;
      else if (type === "district")
        return this.studentPerformanceData.masteryDistrict;
      else if (type === "state")
        return this.studentPerformanceData.masteryState;

      return "";
    } else if (this.sideBarName === "creditAccumulation") {
      if (type === "school")
        return this.studentPerformanceData.creditAccumulation;
      else if (type === "district")
        return this.studentPerformanceData.creditAccumulationDistrict;
      else if (type === "state")
        return this.studentPerformanceData.creditAccumulationState;

      return "";
    } else if (this.sideBarName === "gradRate4yr") {
      if (type === "school") return this.studentPerformanceData.gradRate4yr;
      else if (type === "district")
        return this.studentPerformanceData.gradRate4yrDistrict;
      else if (type === "state")
        return this.studentPerformanceData.gradRate4yrState;

      return "";
    } else if (this.sideBarName === "gradsAnyCredentials") {
      if (type === "school")
        return this.studentPerformanceData.gradsAnyCredentials;
      else if (type === "district")
        return this.studentPerformanceData.gradsAnyCredentialsDistrict;
      else if (type === "state")
        return this.studentPerformanceData.gradsAnyCredentialsState;
      return "";
    } else if (this.sideBarName === "collegeEnrollment") {
      if (type === "school")
        return this.studentPerformanceData.collegeEnrollment;
      else if (type === "district")
        return this.studentPerformanceData.collegeEnrollmentDistrict;
      else if (type === "state")
        return this.studentPerformanceData.collegeEnrollmentState;

      return "";
    } else if (this.sideBarName === "fafsaRates") {
      if (type === "school") return this.studentPerformanceData.fafsaRates;
      else if (type === "district")
        return this.studentPerformanceData.fafsaRatesDistrict;
      else if (type === "state")
        return this.studentPerformanceData.fafsaRatesState;

      return "";
    } else if (this.sideBarName === "masteryHighSchool") {
      if (type === "school")
        /* return this.getDataPoint(this.studentPerformanceData.masteryHighSchool); */
        return this.studentPerformanceData.masteryHighSchool;
      else if (type === "district")
        return this.studentPerformanceData.masteryHighSchoolDistrict;
      else if (type === "state")
        return this.studentPerformanceData.masteryHighSchoolState;

      return "";
    } else if (this.sideBarName === "act21") {
      if (type === "school")
        /* return this.getDataPoint(this.studentPerformanceData.act21); */
        return this.studentPerformanceData.act21;
      else if (type === "district")
        return this.studentPerformanceData.act21District;
      else if (type === "state") return this.studentPerformanceData.act21State;

      return "";
    } else if (this.sideBarName === "actComposite") {
      if (type === "school") return this.studentPerformanceData.actComposite;
      else if (type === "district")
        return this.studentPerformanceData.actCompositeDistrict;
      else if (type === "state")
        return this.studentPerformanceData.actCompositeState;

      return "";
    } else if (this.sideBarName === "studentsStayingOnTrack") {
      if (type === "school")
        return this.studentPerformanceData.studentsStayingOnTrack;
      else if (type === "district")
        return this.studentPerformanceData.studentsStayingOnTrackDistrict;
      else if (type === "state")
        return this.studentPerformanceData.studentsStayingOnTrackState;

      return "";
    } else if (this.sideBarName === "coreCredentialRateAlt") {
      if (type === "school")
        return this.studentPerformanceData.coreCredentialRateAlt;
      else if (type === "district")
        return this.studentPerformanceData.coreCredentialRateAltDistrict;
      else if (type === "state")
        return this.studentPerformanceData.coreCredentialRateAltState;

      return "";
    }
    return "";
  }
  getDataPoint(data: string) {
    let result;
    if (isNaN(+data)) result = data;
    else if (data && data.length > 0) result = `${data}%`;
    return result;
  }

  getGrade(data: string) {
    if (data) {
      data = data.replace(">", "");
      if (isNaN(parseFloat(data))) return "";

      const score = parseFloat(data);
      return this.arrGrades?.find((f) => f.minScore < score)?.grade;
    }
    return "";
  }

  getDataPointPercentage(data: string) {
    if (data && data.length > 0) {
      const data2 = data.replace(">", "");
      if (isNaN(parseFloat(data2))) return data;
      else return `${data}%`;
    }
    return "";
  }
  get sidebarWidth(): string {
    let result = "";
    if (this.mobileView) {
      result = "100%";
    } else if (this.tabletView) result = "40%";
    return result;
  }

  currentLineChart: Chart | undefined;
  chartIsCreated = false;

  viewDetails() {
    const lineDatas: string[] = [];
    const linelabels: string[] = [];
    this.isChartDataExists = false;
    const chartData = this.studentPerformanceData.sideBarScores.find(
      (f) => f.metric === this.sideBarName && f.chartData.length > 0
    )?.chartData;

    if (
      !chartData ||
      chartData.filter(
        (data) =>
          !["", "Not reported due to insufficient population size"].includes(
            data.result
          )
      ).length === 0
    )
      return;

    this.isChartDataExists = true;
    const finalData: any[] = [
      {
        label: "",
        backgroundColor: "#385463",
        borderColor: "#493698",
        data: lineDatas,
        useLineStyle: true,
        usePointStyle: true,
      },
    ];

    chartData.forEach((element) => {
      linelabels.push(`${parseInt(element.year) - 1}-${element.year}`);
      lineDatas.push(element.result.replace(">", ""));
    });

    finalData[0].data = lineDatas;
    if (this.chartIsCreated) {
      console.log("before destroy");

      (this.currentLineChart as Chart).data.labels = linelabels;
      (this.currentLineChart as Chart).data.datasets = finalData;
      (this.currentLineChart as Chart).update();
    } else {
      this.currentLineChart = drawLineChart(
        "lineChart",
        linelabels,
        finalData,
        true
      );
      this.chartIsCreated = true;
    }
  }

  get whatIsStudentPerformanceResultset() {
    //use enum or array for id of the different objects here
    return [
      {
        id: 1,
        header: "For Elementary/Middle School",
        message:
          "Student performance is based on students’ mastery of key content for their grade level; if the school has 8th grade students, it also includes information on students’ successful transition into 9th grade.",
      },
      {
        id: 2,
        header: "For High School",
        message:
          "Student performance is based on how well students are mastering key skills and content for their grade, in addition to how well the school is preparing students for success in college or a career.",
      },
      {
        id: 3,
        header: "For Alternative Schools",
        message:
          "Student performance is based on core academic credit accumulation, successful transition into the next school year, and how well schools are preparing students for college and a career.",
      },
    ];
  }

  get yearFromEntity(){
    let result;
    if(this.schoolSystem)
    {
        result = this.overallPerformance.schoolSystemCurrentYear.letterGradeYearFormatted;
    }
      else{
        result = this.overallPerformance.schoolCurrentYear.letterGradeYearFormatted;
      }
    return result;
  }

  get whatIsStudentPerformanceResult() {
    const result = [] as any;

    if (
      this.schoolAbout &&
      this.schoolAbout.schooltype === "Alternative school"
    ) {
      result.push(this.whatIsStudentPerformanceResultset[2]);
      return result;
    }

    if (
      this.schoolAbout &&
      (this.schoolAbout.isElementarySchool || this.schoolAbout.isMiddleSchool)
    )
      result.push(this.whatIsStudentPerformanceResultset[0]);
    if (this.schoolAbout && this.schoolAbout.isHighSchool)
      result.push(this.whatIsStudentPerformanceResultset[1]);

    return result;
  }

  get sideBarTitleDataWidth() {
    let result;

    if (this.mobileView) result = "w-40";
    else if (this.tabletView) result = "w-20";

    return result;
  }

  nationalAverageACTComposite = 20.7;
  nationalAverageGradRate4yr = 85;

  nationalAverage(sideBarName: string) {
    let result;
    if (sideBarName === "actComposite") {
      result = this.nationalAverageACTComposite;
    } else if (sideBarName === "gradRate4yr") {
      result = this.nationalAverageGradRate4yr;
    }
    return result;
  }

  get showMasterKeySkills() {
    return !(
      this.school.studentPerformance.mastery === "" &&
      this.school.studentPerformance.masteryHighSchool === "" &&
      this.school.studentPerformance.creditAccumulation === "" &&
      this.school.studentPerformance.act21 === "" &&
      this.school.studentPerformance.actComposite === ""
    );
  }

  get showSchoolPreparingHighSchoolStudents() {
    return !(
      this.school.studentPerformance.gradRate4yr === "" &&
      this.school.studentPerformance.gradsAnyCredentials === "" &&
      this.school.studentPerformance.collegeEnrollment === "" &&
      this.school.studentPerformance.fafsaRates === "" &&
      this.school.studentPerformance.studentsStayingOnTrack === "" &&
      this.school.studentPerformance.coreCredentialRateAlt === ""
    );
  }

  get ratingLabelLayout() {
    return {
      "col-4": this.tabletView,
      "col-5": this.mobileView,
    };
  }

  get ratingBadgeLayout() {
    return {
      "col-8": this.tabletView,
      "col-7": this.mobileView,
    };
  }
}
